.visibility-none {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20px;
    width: 1px;
}

.table-row-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.badge {
    color: #000 !important;
    background-color: #F5F5F5 !important;
    border-radius: 20px !important;
    padding: 5px 13px !important;
    font-weight: 400 !important;
    border: 1px solid #C6C6C6;

    &:hover {
        cursor: pointer;
    }

    &.selected {
        background-color: #FFBC36 !important;
    }
}

.MuiTableSortLabel-root {

    color: #000 !important;

    &.MuiTableSortLabel-active {
        color: #000 !important;
    }
}

.MuiTableCell-root {
    max-width: 250px !important;
}

.MuiTableCell-head {
    font-weight: 600;
}