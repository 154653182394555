@import '../../../../../styles/theme.scss';

.aggregation-text {
    font-size: 1em;
}

.aggregation {
    .col {
        &:nth-child(3) {
            color: $text-grey;
        }
    }
}

.aggregation-value {
    font-size: 1.4em;
    font-weight: bold;
}

.filter-wrapper {
    position: absolute;
    bottom: 18px;
    right: 26px;
}

.aggregation-text-wrapper {
    min-height: auto;
}

.arrow-up {
    margin-left: 7px;
    margin-top: -5px;
    transform: rotateZ(-90deg);
    color: $green;
}

.align-items-right {
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
}

.total-aggregation {
    color: #808080;
}