.master-trainer {
  .caption {
    font-size: 16px;
    color: #000;
    padding: 0 16px 20px 0;
    margin-top: 15px;
    font-weight: 600;
  }

  .table-wrapper {
    background-color: #f8f8f8;

    .table-head-row {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 10px;
      align-items: center;

      .left {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 17px !important;
        // font-size: 1rem;
      }

      .right {
        a {
          color: #ba1f00;
          font-weight: 500;
          font-size: 1rem;
        }
      }
    }
  }

  .small-container {
    .section-info-wrapper {
      width: 45%;

      .sec-heading {
        background-color: #f5f5f5;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: bold;
        display: flex;
        align-items: center;
        border-bottom: 3px solid black;

        .text {
          font-size: 18px;
        }
      }
    }

    .section-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5f5f5;
      padding: 10px;
      border-bottom: 1px solid #c8c8c8;

      // margin-bottom: 65px;

      .left {
        font-weight: 500;
        font-size: 18px;
      }

      .line {
        .bold {
          font-weight: bold;
          color: #000;
        }

        .big {
          font-size: 1.6rem;
        }
      }

      .numbers {
        // padding: 10px 100px;
      }
    }
  }

  .heading-row {
    display: flex;
    flex-direction: column;

    margin-bottom: 25px;

    .heading {
      // text-transform: uppercase;
      font-size: 1.5rem;
      color: #b40000;
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    .sub-heading {
      font-size: 1.2rem;
      // font-weight: 500;
    }
  }

  .close-btn-w {
    display: flex;
    // position: fixed;
    // top: 65px;
    // right: 0;

    .close-btn {
      padding: 10px 30px;
      // text-transform: uppercase;
      background-color: #e8e8e8;
      color: #b40000;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        margin: 0 10px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .selection {
    display: flex;
    align-items: center;
    margin: 0 25px;

    .s-label {
      margin-right: 10px;
      // font-weight: bold;
      color: #898989;
      font-size: 13px;
    }

    .select-form-control {
      border: 1px solid #a9a9a9;
      border-radius: 4px;
    }
  }

  .view-details {
    .b-row {
      .t-head {
        font-weight: bold;
      }
    }
  }

  .a-data {
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e0e0e0;
    color: gray;
    font-size: 18px;
  }
}
.parent {
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
}
.left_red {
  background-color: rgb(212, 212, 212);
  padding: 10px;
  border-radius: 5px;
  width: 32%;
  display: flex;
  justify-content: flex-end;
  .close-btn {
    color: #b40000 !important;
    font-size: larger;
  }
}
