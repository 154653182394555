@import "../../styles/theme.scss";

.logo {
  float: left;
  width: 95%;
}

h2 {
  float: left;
}

.bput {
  height: 58px;
  float: left;
}

.new-user {
  color: $grey;
}

.forgot-password {
  color: $light-grey;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 320px) {
    margin-top: 13rem;
  }
}
// .copyright {
//   .illumin-logo {
//     // position: fixed;
//     bottom: 2rem;
//     left: 2rem;
//   }
//   // position: fixed;
//   right: 2rem;
//   bottom: 2rem;
// }
.card {
  @media screen and (max-width: 320px) {
  }
}
