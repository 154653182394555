$background: #f7f7f7;

$gold: #886c53;
$primary: #f7b742;
$primary-dark: #d69b3a;
$primary-assent: #f7b742;
$primary-border: #f7b742;

$secondary: #fb7078;
$secondary-assent: #fde4e6;

$dark: #040405;
$light-dark: #262630;
$light: #ebf1fa;

$green: #03c99c;

$white: #ffffff;
$white-bg: #fafafa;
$white-assent: #ebf1fa;

$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

// break-points
$media-tab: 576px;

$menu-active: #b40000;
$grey: #5d5d5d;
$light-grey: #989898;
$text-grey: #808080;
$green: #61c905;

$universal-color: #000;

$accordian-bgcolor: #eee;
$accordian-color: #eac6b7;
$accordian-menuitem-color: #edcfc2;

$utilities-border-colors: black;
$negative-spacers: 0;
$utilities-text-colors: ();
$utilities-bg-colors: ();
$theme-colors-rgb: ();
$gutters: ();
