.modal {
	width: 400px;
	top: 50%;
	transform: translateY(-50%);
	position: fixed;
	background-color: #fff;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}