.MasterTraineName {
  padding: 20px 10px 10px;
  background-color: #d9d9d9;
  font-size: 16;
  font-weight: 700;
}
.close-btn {
  padding: 10px 30px;
  // text-transform: uppercase;

  color: #b40000;
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    margin: 0 10px;
  }

  &:hover {
    text-decoration: none;
  }
}

.MAsterAcc {
  color: #ba1f00;
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.masterNameDiv {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-family: "Fira Sans", sans-serif !important;
  font-size: 17.6px !important;
  font-weight: 500 !important;
  // padding: 0px 10px !important;
  padding: 0.5rem 1rem 0.5rem;
  &:hover {
    background-color: white;
  }
}
