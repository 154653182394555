@import '../../styles/theme.scss';

.sidebar {
    position: fixed;
    width: 20%;
    // width: 333px;
    height: 100%;
    padding: 4.5% 0 10px;
    z-index: 30;
    top: 0;
    margin-left: 0;

    .sidebar-body {
        height: 100%;
        overflow-y: auto;

        .updatedDate {
            font-size: 0.9rem;
            text-align: center;
            padding: 10px 0;
        }

        .side-nav {
            margin: 0;
            overflow: hidden;

            .menu-group {
                background-color: #EEE;
                margin: 0;
                border-radius: 0;

                .menu-category {
                    border-bottom: 1px solid rgba(224, 224, 224, 1);
                }

                .category-heading {
                    color: #BA1F00;
                    font-weight: bold;
                    font-size: 0.9rem;
                    text-transform: uppercase;
                }

                .side-nav-links {
                    color: $dark;
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem 0.5rem;
                    line-height: 1.2;
                    position: relative;

                    .side_que {
                        display: flex;
                        flex-direction: column;
                        padding-left: 35px;
                    }

                    &.active {
                        .side_que {
                            padding-left: 0;
                        }
                    }

                    &:active,
                    &:hover,
                    &.active {
                        background-color: white;
                        text-decoration: none;

                        .menu-name {
                            color: black;
                        }
                    }

                    &.active {
                        background-color: transparent;

                        // &:before {
                        //     content: "";
                        //     border-left: 14px solid #B40000;
                        //     border-top: 14px solid transparent;
                        //     border-right: 14px solid transparent;
                        //     border-bottom: 14px solid transparent;
                        //     width: 0;
                        //     height: 0;
                        //     position: absolute;
                        //     left: 16px;
                        //     top: 50%;
                        //     transform: translateY(-50%);
                        // }

                        .menu-name {
                            color: black;
                            opacity: 1;
                        }
                    }
                }

                .menu-name {
                    color: black;
                    font-weight: 500;
                    opacity: .7;
                }

                .panel-details {
                    background-color: #F7F7F7;
                    padding: 10px 0;
                }
            }
        }

        .custom-accordian {
            display: flex;
            padding: 0px 16px;
            background-color: #EEE;
            position: relative;

            &::before {
                top: -1px;
                left: 0;
                right: 0;
                height: 1px;
                content: "";
                opacity: 1;
                position: absolute;
                transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                background-color: rgba(0, 0, 0, 0.12);
            }

            .custom-content-wrapper {
                margin: 12px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .custom-heading {
                    color: #EAC6B7;
                }

                .right-icon {
                    display: none;
                }
            }

            &.active {
                .custom-heading {
                    color: white;
                }

                .right-icon {
                    display: block;
                }
            }
        }
    }
}

.acp-sidebar {
    margin-left: 0px;
}