.footer {
    // background-color: #fff;
    // color: #8C8C8C;
    // z-index: 1049;
    position: relative;
    // border-block-start: 1px solid black;
    background-color: #fff;
    border-block-start: 1px solid #ccc;
    color: #8c8c8c;
    position: relative;
    z-index: 1049;
}

.bt-1 {
    border-top: 0.2px solid #e2e2e2;
}

.footer-items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        display: inline;
        margin-right: 30px;

        a {
            font-size: .9rem;
        }
    }

    .footer-image {
        height: 31px;
        margin-top: -5px;
    }

    &.float-right {
        float: right;
    }
}