@import '../../../styles/theme.scss';

.widget {
    min-height: 200px;
    height: auto;
    position: relative;
    background-color: $white !important;
    // height: 'fit-content';
    // width: 656px;
}

.card {
    border: 1px solid #DCDCDC;
    border-radius: 0.5rem !important;
}

.chart-wrapper {
    width: 70%;
    margin: 0 auto;
    align-self: center;

    &.pie-chart-w {
        width: 50%;
    }
}

.vd-btn-w {
    display: flex;
    justify-content: flex-end;

    a {
        padding-left: 35px;
        padding-right: 35px;
        border-radius: 14px;
        height: 39px;
        box-shadow: none;
        display: flex;
        background-color: #ffbc36;
        align-items: center;
        text-transform: uppercase;
    }
}