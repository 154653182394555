@import "../../styles/theme.scss";

.bread-crumb-list {
  list-style: none;
  display: flex;
  margin-bottom: 0;
  padding-left: 0;

  li {
    .bread-crumb-item {
      float: left;
      font-weight: 400;
      font-size: 1.35em;

      &:after {
        content: "navigate_next";
        font-family: "Material Icons";
        display: inline-flex;
        vertical-align: middle;
        margin-left: 10px;
        font-size: inherit;
      }
    }

    &:last-child {
      .bread-crumb-item {
        &:after {
          content: "";
        }
      }
    }
  }
}

.page-hint {
  font-size: 0.9em;
  color: #686868;
}

.column {
  .question {
    font-weight: 500;
    font-size: 18px;
  }
}

.category-heading {
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}

section:first-child {
  scroll-margin-top: 120px;
}

section:not(:first-child) {
  scroll-margin-top: 72px;
  padding-top: 30px;
}

.section {
  margin: 0 50px;

  &.card {
    background-color: #ffffff;
    padding: 2em;
    border-radius: 1.5em !important;
  }

  * {
    font-size: 0.9rem;
  }
}

.welcome {
  padding: 51px 118px;
  border-radius: 20px;
  background-color: white;
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .line {
    font-weight: 500;
    margin: 8px 0;
  }
}
