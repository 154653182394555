@import "../../../styles/theme.scss";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(255, 255, 255, 0.6);
  background-color: #ffff;
  z-index: 1051;

  .spinner {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translatey(-50%);
  }
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  margin: 16px;
  animation: rotate360 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid $primary;
  border-right: 2px solid $primary;
  border-bottom: 2px solid $primary;
  border-left: 4px solid $primary-dark;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
