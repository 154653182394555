.view-details {
    .caption {
        background-color: #ECECEC;
        font-size: 1.5rem;
        color: #000;
        padding: 15px 0;
        margin-top: 15px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .filters-title {
        font-size: 1.4rem;
        text-transform: uppercase;
        color: #A0A0A0;
        padding-bottom: 15px;
        background: #F8F8F8;
    }

    .heading-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .heading {
            // text-transform: uppercase;
            font-size: 1.5rem;
            color: #B40000;
            font-weight: 500;
        }

        .sub-heading {
            font-size: 1.2rem;
            // font-weight: 500;
        }
    }

    .close-btn-l {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 65px;
        left: 0;

        .close-btn {
            padding: 10px 30px;
            text-transform: capitalize;
            background-color: #E8E8E8;
            color: #B40000;
            font-weight: 600;
            display: flex;
            align-items: center;

            svg {
                margin: 0 10px;
            }

            &:hover {
                text-decoration: none;
            }

        }

        .MuiButton-label {
            font-weight: 500;
        }
    }

    .close-btn-w {
        display: flex;
        flex-direction: column;
        // position: fixed;
        // top: 65px;
        // right: 0;

        .close-btn {
            // width: 280px;
            padding: 10px 30px;
            text-transform: capitalize;
            background-color: #E8E8E8;
            color: #B40000;
            font-weight: 600;
            display: flex;
            align-items: center;

            svg {
                margin: 0 10px;
            }

            &:hover {
                text-decoration: none;
            }

        }

        .MuiButton-label {
            font-weight: 500;
        }

        .download-control {
            background-color: #B40000;
            padding: 10px 30px;
            display: flex;
            color: white;
            justify-content: center;

            .download-btn {
                padding: 0;
                color: white;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
    }

    .selection {
        display: flex;
        align-items: center;
        background: #F8F8F8;
        // justify-content: space-between;
        // padding-bottom: 38px;        

        .control {
            display: flex;
            flex-direction: column;
            padding-right: 15px;

            .select-form-control {
                border: 1px solid #A9A9A9;
                border-radius: 4px;
            }
        }

        .s-label {
            margin-right: 10px;
            font-weight: bold;
            margin-bottom: 10px;
        }

    }

    .view-details {
        .b-row {
            .t-head {
                font-weight: bold;
            }
        }
    }

    .a-data {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e0e0e0;
        color: gray;
        font-size: 18px;
    }
}

#demo-popup-menu {
    .menu-item {
        span {
            display: inherit;
            align-items: inherit;
            justify-content: inherit;
        }
    }
}