@import "../../styles/theme.scss";

.header {
  background-color: #ffffff;
  color: #040405;
  padding: 0.75%;
  position: fixed;
  z-index: 1200;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 480px) {
    .log_out_icon {
      // background-color: red;
      position: relative;
    }
    .icon_logout {
      position: absolute;
      bottom: 1px;
      width: 30px;
      height: 22px;
      right: 5px;
    }
  }
  .left {
    display: flex;
    align-items: center;
    @media screen and (max-width: 320px) {
      padding: 1rem 1.5rem;
      //   padding: 5px;
    }
  }

  .navbar-brand,
  .nav-link {
    color: #ffffff;
    text-decoration: none;

    &:active,
    &:hover,
    &:visited,
    &.focus {
      color: #ffffff;
      text-decoration: none;
    }
  }

  .d-block {
    float: left;
  }

  .header-logo {
    height: 40px;
    float: left;
    @media screen and (max-width: 320px) {
      height: 30px;
      //   padding: 5px;
    }
  }

  h3 {
    float: left;
    font-size: 16px;
    text-transform: uppercase;
    @media screen and (max-width: 320px) {
      font-size: 12px;
    }
  }

  .br-1 {
    padding-right: 10px;
    border-right: 1px solid #c6c6c6;
  }

  .user-name {
    float: right;
    margin-right: 10px;
    cursor: pointer;
  }
}
.right_side {
  @media screen and (max-width: 320px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 20px 5px 0;
    margin-top: -4rem;
    margin-left: -2.5rem;
  }
}
// section {
// 	scroll-margin-top: 5rem;
// }
