.spinner-loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	z-index: 1051;

	.spinner {
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		top: 25%;
		transform: translatey(-50%);
	}
}