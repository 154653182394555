.content {
    .caption {
        font-size: 12px;
        color: gray;
        margin: 15px 0;
        font-weight: bold;
    }

    .page-heading {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 15px;

    }

    .item {
        display: flex;
        align-items: center;

        .item-label {
            margin-right: 10px;
            font-weight: bold;
        }
    }

    .show-btn-w {
        display: flex;
        justify-content: center;
        margin: 15px 0;

        .show-btn {
            font-weight: bold;
            border-radius: 12px;
        }
    }

    .snapshot-details {
        margin-top: 25px;
        border-top: 1px solid rgba(224, 224, 224, 1);

        .t-head {
            font-weight: bold;
        }

        .s-completed {
            font-size: 18px;
        }

        .active-cell {
            display: flex;
            align-items: center;
        }

        .status {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: block;
            background-color: #B8B8B8;
            margin-right: 5px;

            &.active {
                background-color: #58D405;
            }
        }

        .b-row {

            .b-td {
                border-left: 1px solid #e0e0e0;
            }
        }
    }

    .a-data {
        padding: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e0e0e0;
        color: gray;
        font-size: 18px;
    }
}